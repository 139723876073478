import React, {
  type ChangeEvent,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import SearchIcon from '../../assets/icons/search-icon.svg';
import CheckBlue from '../../assets/icons/check-blue.svg';
import {
  Country,
  IPhonenumberDropdownProps,
  countries,
} from './PhonenumberInput.interface';
import { P } from '../Typography';

const StyledCheckBlue = styled(CheckBlue)`
  margin-left: 5px;
`;

const DropDownHeader = styled.div`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;

  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 7px 7px 10px;
  position: relative;
`;

const ValueSelect = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #000;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 99;
  box-sizing: border-box;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(129, 129, 165, 0.2);
`;

const DropDownList = styled.ul`
  box-sizing: border-box;
  padding: 0;
  margin: 10px 0;
  background: #fff;
  overflow: auto;
  max-height: 206px;
`;

const SearchInput = styled.input`
  border: none;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #000;
  padding: 4px 10px 4px 25px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin: 0;

  &:focus-within {
    border: none;
  }

  ::placeholder {
    color: #000;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f4f7f9;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CountryName = styled(P)`
  font-weight: 300;
  font-size: 15px;
  line-height: 16px;
  color: #000;
`;

const CountryNumberCode = styled(CountryName)`
  color: #8181a5;
  margin-left: 5px;
`;

const FlagContainer = styled.div<{ isCategoryItem?: boolean }>`
  ${({ isCategoryItem }) =>
    isCategoryItem ? `margin-right: 7px;` : `margin-right: 2px;`}
  display: flex;
  align-items: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  width: 22px;
  height: 16px;
`;

const InputSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(129, 129, 165, 0.2);
`;

const Search = styled.input<{
  disabled?: boolean;
}>`
  border: none;
  width: 100%;
  font-family: 'Montserrat';
  color: #000;
  font-size: 16px;
  font-weight: 300;

  padding: 7px 10px 7px 0;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    outline: none;
    color: #000;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 300;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  left: 13px;
  top: 7px;
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ef6355;
  margin-top: 5px;
`;

const CountryCode = styled.span`
  margin: 0 0 0 2px;
`;

const CountryFlagIcon: React.FunctionComponent<{
  code: FlagIconCode;
  isCategoryItem?: boolean;
}> = ({ code, isCategoryItem }) => (
  <FlagContainer isCategoryItem={isCategoryItem}>
    <StyledFlagIcon code={code} />
  </FlagContainer>
);

export const PhonenumberInput: React.FunctionComponent<
  IPhonenumberDropdownProps
> = ({ onChange, value, disabled, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchCountry, setSearchCountry] = useState<string>(``);

  const [selectedCountry, setSelectedCountry] = useState<Country>({
    countryName: `United Kingdom`,
    code: `+44`,
    country: `GB`,
  });
  const [enteredNumber, setEnteredNumber] = useState<string>(``);

  useEffect(() => {
    if (value) {
      setEnteredNumber(value.number);
      const initialCountry = countries.find(
        (country) => country.code === value?.code,
      );
      setSelectedCountry({
        countryName: initialCountry?.countryName ?? `United Kingdom`,
        code: initialCountry?.code ?? `+44`,
        country: initialCountry?.country ?? `GB`,
      });
    }
  }, [value]);

  const ref = useRef(null);
  const valueRef = useRef(null);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const clickOnWindows = (e: MouseEvent) => {
      if (
        ref.current !== e.target &&
        e.target !== valueRef.current &&
        e.target !== searchRef.current
      ) {
        setIsOpen(false);
        setSearchCountry(``);
      }
    };
    window.addEventListener(`click`, clickOnWindows);

    return () => {
      window.removeEventListener(`click`, clickOnWindows);
    };
  }, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCountry(event.target.value);
  };

  const handlePhonenumber = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredNumber(event.target.value);
    onChange({
      code: selectedCountry.code,
      number: event.target.value,
      countryCode: selectedCountry.country,
    });
  };

  const handleItemClick = (country: Country) => () => {
    if (country.country === selectedCountry.country) {
      onChange({
        code: country.code,
        number: enteredNumber,
        countryCode: country.country,
      });
      setIsOpen(!isOpen);
    } else {
      setSelectedCountry(country);
      setSearchCountry(``);
      onChange({
        code: country.code,
        number: enteredNumber,
        countryCode: country.country,
      });
      setIsOpen(!isOpen);
    }
  };

  const handleExpand = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isOpen && !disabled) {
        e.stopPropagation();
        setIsOpen(true);
        setTimeout(() => {
          searchRef.current?.focus();
        }, 100);
      }
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen, disabled],
  );

  const filteredCountries = useMemo(
    () =>
      countries.filter((c) =>
        c.countryName.toLowerCase().includes(searchCountry.toLowerCase()),
      ),
    [searchCountry],
  );

  return (
    <Container>
      <InputSection>
        <DropDownHeader ref={ref} onClick={handleExpand}>
          <ValueSelect ref={valueRef}>
            <CountryFlagIcon code={selectedCountry.country} />
            <CountryCode>{selectedCountry.code}</CountryCode>
          </ValueSelect>
        </DropDownHeader>

        {isOpen && (
          <DropDownListContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ position: `relative`, padding: `0 14px` }}>
              <SearchInput
                id="countrySearchField"
                type="text"
                value={searchCountry}
                onChange={handleSearch}
                placeholder="Search country"
              />
              <StyledSearchIcon />
            </div>
            <DropDownList>
              {filteredCountries.map((country) => (
                <div key={country.countryName}>
                  <Item onClick={handleItemClick(country)}>
                    <ItemWrapper>
                      <CountryFlagIcon
                        isCategoryItem={isOpen}
                        code={country.country}
                      />
                      <CountryName>{country.countryName}</CountryName>
                      <CountryNumberCode>{country.code}</CountryNumberCode>
                    </ItemWrapper>
                    {selectedCountry.code === country.code && (
                      <StyledCheckBlue />
                    )}
                  </Item>
                </div>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
        <Search
          id="phoneNumberField"
          placeholder="Phone number"
          disabled={disabled}
          type="text"
          value={enteredNumber}
          onChange={handlePhonenumber}
        />
      </InputSection>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};
