import { type FlagIconCode } from 'react-flag-kit';

export interface PhoneInputValues {
  code: string;
  number: string;
  countryCode: string;
}

export interface IPhonenumberDropdownProps {
  value?: PhoneInputValues;
  onChange: (value: PhoneInputValues) => void;
  label?: string | JSX.Element;
  required?: boolean;
  disabled?: boolean;
  dropdownColor?: string;
  error?: string;
}

export interface Country {
  countryName: string;
  code: string;
  country: FlagIconCode;
}

export const countries: Country[] = [
  {
    countryName: `United Kingdom`,
    code: `+44`,
    country: `GB`,
  },
  {
    countryName: `Afghanistan`,
    code: `+93`,
    country: `AF`,
  },
  {
    countryName: `Åland Islands`,
    code: `+358`,
    country: `AX`,
  },
  {
    countryName: `Albania`,
    code: `+355`,
    country: `AL`,
  },
  {
    countryName: `Algeria`,
    code: `+213`,
    country: `DZ`,
  },
  {
    countryName: `AmericanSamoa`,
    code: `+1684`,
    country: `AS`,
  },
  {
    countryName: `Andorra`,
    code: `+376`,
    country: `AD`,
  },
  {
    countryName: `Angola`,
    code: `+244`,
    country: `AO`,
  },
  {
    countryName: `Anguilla`,
    code: `+1264`,
    country: `AI`,
  },
  {
    countryName: `Antigua and Barbuda`,
    code: `+1268`,
    country: `AG`,
  },
  {
    countryName: `Argentina`,
    code: `+54`,
    country: `AR`,
  },
  {
    countryName: `Armenia`,
    code: `+374`,
    country: `AM`,
  },
  {
    countryName: `Aruba`,
    code: `+297`,
    country: `AW`,
  },
  {
    countryName: `Australia`,
    code: `+61`,
    country: `AU`,
  },
  {
    countryName: `Austria`,
    code: `+43`,
    country: `AT`,
  },
  {
    countryName: `Azerbaijan`,
    code: `+994`,
    country: `AZ`,
  },
  {
    countryName: `Bahamas`,
    code: `+1242`,
    country: `BS`,
  },
  {
    countryName: `Bahrain`,
    code: `+973`,
    country: `BH`,
  },
  {
    countryName: `Bangladesh`,
    code: `+880`,
    country: `BD`,
  },
  {
    countryName: `Barbados`,
    code: `+1246`,
    country: `BB`,
  },
  {
    countryName: `Belarus`,
    code: `+375`,
    country: `BY`,
  },
  {
    countryName: `Belgium`,
    code: `+32`,
    country: `BE`,
  },
  {
    countryName: `Belize`,
    code: `+501`,
    country: `BZ`,
  },
  {
    countryName: `Benin`,
    code: `+229`,
    country: `BJ`,
  },
  {
    countryName: `Bermuda`,
    code: `+1441`,
    country: `BM`,
  },
  {
    countryName: `Bhutan`,
    code: `+975`,
    country: `BT`,
  },
  {
    countryName: `Bolivia, Plurinational State of`,
    code: `+591`,
    country: `BO`,
  },
  {
    countryName: `Bosnia and Herzegovina`,
    code: `+387`,
    country: `BA`,
  },
  {
    countryName: `Botswana`,
    code: `+267`,
    country: `BW`,
  },
  {
    countryName: `Brazil`,
    code: `+55`,
    country: `BR`,
  },
  {
    countryName: `British Indian Ocean Territory`,
    code: `+246`,
    country: `IO`,
  },
  {
    countryName: `Brunei Darussalam`,
    code: `+673`,
    country: `BN`,
  },
  {
    countryName: `Bulgaria`,
    code: `+359`,
    country: `BG`,
  },
  {
    countryName: `Burkina Faso`,
    code: `+226`,
    country: `BF`,
  },
  {
    countryName: `Burundi`,
    code: `+257`,
    country: `BI`,
  },
  {
    countryName: `Cambodia`,
    code: `+855`,
    country: `KH`,
  },
  {
    countryName: `Cameroon`,
    code: `+237`,
    country: `CM`,
  },
  {
    countryName: `Canada`,
    code: `+1`,
    country: `CA`,
  },
  {
    countryName: `Cape Verde`,
    code: `+238`,
    country: `CV`,
  },
  {
    countryName: `Cayman Islands`,
    code: `+ 345`,
    country: `KY`,
  },
  {
    countryName: `Central African Republic`,
    code: `+236`,
    country: `CF`,
  },
  {
    countryName: `Chad`,
    code: `+235`,
    country: `TD`,
  },
  {
    countryName: `Chile`,
    code: `+56`,
    country: `CL`,
  },
  {
    countryName: `China`,
    code: `+86`,
    country: `CN`,
  },
  {
    countryName: `Christmas Island`,
    code: `+61`,
    country: `CX`,
  },
  {
    countryName: `Cocos (Keeling) Islands`,
    code: `+61`,
    country: `CC`,
  },
  {
    countryName: `Colombia`,
    code: `+57`,
    country: `CO`,
  },
  {
    countryName: `Comoros`,
    code: `+269`,
    country: `KM`,
  },
  {
    countryName: `Congo`,
    code: `+242`,
    country: `CG`,
  },
  {
    countryName: `Congo, The Democratic Republic of the`,
    code: `+243`,
    country: `CD`,
  },
  {
    countryName: `Cook Islands`,
    code: `+682`,
    country: `CK`,
  },
  {
    countryName: `Costa Rica`,
    code: `+506`,
    country: `CR`,
  },
  {
    countryName: `Cote d'Ivoire`,
    code: `+225`,
    country: `CI`,
  },
  {
    countryName: `Croatia`,
    code: `+385`,
    country: `HR`,
  },
  {
    countryName: `Cuba`,
    code: `+53`,
    country: `CU`,
  },
  {
    countryName: `Cyprus`,
    code: `+537`,
    country: `CY`,
  },
  {
    countryName: `Czech Republic`,
    code: `+420`,
    country: `CZ`,
  },
  {
    countryName: `Denmark`,
    code: `+45`,
    country: `DK`,
  },
  {
    countryName: `Djibouti`,
    code: `+253`,
    country: `DJ`,
  },
  {
    countryName: `Dominica`,
    code: `+1767`,
    country: `DM`,
  },
  {
    countryName: `Dominican Republic`,
    code: `+1849`,
    country: `DO`,
  },
  {
    countryName: `Ecuador`,
    code: `+593`,
    country: `EC`,
  },
  {
    countryName: `Egypt`,
    code: `+20`,
    country: `EG`,
  },
  {
    countryName: `El Salvador`,
    code: `+503`,
    country: `SV`,
  },
  {
    countryName: `Equatorial Guinea`,
    code: `+240`,
    country: `GQ`,
  },
  {
    countryName: `Eritrea`,
    code: `+291`,
    country: `ER`,
  },
  {
    countryName: `Estonia`,
    code: `+372`,
    country: `EE`,
  },
  {
    countryName: `Ethiopia`,
    code: `+251`,
    country: `ET`,
  },
  {
    countryName: `Falkland Islands (Malvinas)`,
    code: `+500`,
    country: `FK`,
  },
  {
    countryName: `Faroe Islands`,
    code: `+298`,
    country: `FO`,
  },
  {
    countryName: `Fiji`,
    code: `+679`,
    country: `FJ`,
  },
  {
    countryName: `Finland`,
    code: `+358`,
    country: `FI`,
  },
  {
    countryName: `France`,
    code: `+33`,
    country: `FR`,
  },
  {
    countryName: `French Guiana`,
    code: `+594`,
    country: `GF`,
  },
  {
    countryName: `French Polynesia`,
    code: `+689`,
    country: `PF`,
  },
  {
    countryName: `Gabon`,
    code: `+241`,
    country: `GA`,
  },
  {
    countryName: `Gambia`,
    code: `+220`,
    country: `GM`,
  },
  {
    countryName: `Georgia`,
    code: `+995`,
    country: `GE`,
  },
  {
    countryName: `Germany`,
    code: `+49`,
    country: `DE`,
  },
  {
    countryName: `Ghana`,
    code: `+233`,
    country: `GH`,
  },
  {
    countryName: `Gibraltar`,
    code: `+350`,
    country: `GI`,
  },
  {
    countryName: `Greece`,
    code: `+30`,
    country: `GR`,
  },
  {
    countryName: `Greenland`,
    code: `+299`,
    country: `GL`,
  },
  {
    countryName: `Grenada`,
    code: `+1473`,
    country: `GD`,
  },
  {
    countryName: `Guadeloupe`,
    code: `+590`,
    country: `GP`,
  },
  {
    countryName: `Guam`,
    code: `+1671`,
    country: `GU`,
  },
  {
    countryName: `Guatemala`,
    code: `+502`,
    country: `GT`,
  },
  {
    countryName: `Guernsey`,
    code: `+44`,
    country: `GG`,
  },
  {
    countryName: `Guinea`,
    code: `+224`,
    country: `GN`,
  },
  {
    countryName: `Guinea-Bissau`,
    code: `+245`,
    country: `GW`,
  },
  {
    countryName: `Guyana`,
    code: `+595`,
    country: `GY`,
  },
  {
    countryName: `Haiti`,
    code: `+509`,
    country: `HT`,
  },
  {
    countryName: `Holy See (Vatican City State)`,
    code: `+379`,
    country: `VA`,
  },
  {
    countryName: `Honduras`,
    code: `+504`,
    country: `HN`,
  },
  {
    countryName: `Hong Kong`,
    code: `+852`,
    country: `HK`,
  },
  {
    countryName: `Hungary`,
    code: `+36`,
    country: `HU`,
  },
  {
    countryName: `Iceland`,
    code: `+354`,
    country: `IS`,
  },
  {
    countryName: `India`,
    code: `+91`,
    country: `IN`,
  },
  {
    countryName: `Indonesia`,
    code: `+62`,
    country: `ID`,
  },
  {
    countryName: `Iran, Islamic Republic of`,
    code: `+98`,
    country: `IR`,
  },
  {
    countryName: `Iraq`,
    code: `+964`,
    country: `IQ`,
  },
  {
    countryName: `Ireland`,
    code: `+353`,
    country: `IE`,
  },
  {
    countryName: `Isle of Man`,
    code: `+44`,
    country: `IM`,
  },
  {
    countryName: `Israel`,
    code: `+972`,
    country: `IL`,
  },
  {
    countryName: `Italy`,
    code: `+39`,
    country: `IT`,
  },
  {
    countryName: `Jamaica`,
    code: `+1876`,
    country: `JM`,
  },
  {
    countryName: `Japan`,
    code: `+81`,
    country: `JP`,
  },
  {
    countryName: `Jersey`,
    code: `+44`,
    country: `JE`,
  },
  {
    countryName: `Jordan`,
    code: `+962`,
    country: `JO`,
  },
  {
    countryName: `Kazakhstan`,
    code: `+7 7`,
    country: `KZ`,
  },
  {
    countryName: `Kenya`,
    code: `+254`,
    country: `KE`,
  },
  {
    countryName: `Kiribati`,
    code: `+686`,
    country: `KI`,
  },
  {
    countryName: `Korea, Democratic People's Republic of`,
    code: `+850`,
    country: `KP`,
  },
  {
    countryName: `Korea, Republic of`,
    code: `+82`,
    country: `KR`,
  },
  {
    countryName: `Kuwait`,
    code: `+965`,
    country: `KW`,
  },
  {
    countryName: `Kyrgyzstan`,
    code: `+996`,
    country: `KG`,
  },
  {
    countryName: `Lao People's Democratic Republic`,
    code: `+856`,
    country: `LA`,
  },
  {
    countryName: `Latvia`,
    code: `+371`,
    country: `LV`,
  },
  {
    countryName: `Lebanon`,
    code: `+961`,
    country: `LB`,
  },
  {
    countryName: `Lesotho`,
    code: `+266`,
    country: `LS`,
  },
  {
    countryName: `Liberia`,
    code: `+231`,
    country: `LR`,
  },
  {
    countryName: `Libyan Arab Jamahiriya`,
    code: `+218`,
    country: `LY`,
  },
  {
    countryName: `Liechtenstein`,
    code: `+423`,
    country: `LI`,
  },
  {
    countryName: `Lithuania`,
    code: `+370`,
    country: `LT`,
  },
  {
    countryName: `Luxembourg`,
    code: `+352`,
    country: `LU`,
  },
  {
    countryName: `Macao`,
    code: `+853`,
    country: `MO`,
  },
  {
    countryName: `Macedonia, The Former Yugoslav Republic of`,
    code: `+389`,
    country: `MK`,
  },
  {
    countryName: `Madagascar`,
    code: `+261`,
    country: `MG`,
  },
  {
    countryName: `Malawi`,
    code: `+265`,
    country: `MW`,
  },
  {
    countryName: `Malaysia`,
    code: `+60`,
    country: `MY`,
  },
  {
    countryName: `Maldives`,
    code: `+960`,
    country: `MV`,
  },
  {
    countryName: `Mali`,
    code: `+223`,
    country: `ML`,
  },
  {
    countryName: `Malta`,
    code: `+356`,
    country: `MT`,
  },
  {
    countryName: `Marshall Islands`,
    code: `+692`,
    country: `MH`,
  },
  {
    countryName: `Martinique`,
    code: `+596`,
    country: `MQ`,
  },
  {
    countryName: `Mauritania`,
    code: `+222`,
    country: `MR`,
  },
  {
    countryName: `Mauritius`,
    code: `+230`,
    country: `MU`,
  },
  {
    countryName: `Mayotte`,
    code: `+262`,
    country: `YT`,
  },
  {
    countryName: `Mexico`,
    code: `+52`,
    country: `MX`,
  },
  {
    countryName: `Micronesia, Federated States of`,
    code: `+691`,
    country: `FM`,
  },
  {
    countryName: `Moldova, Republic of`,
    code: `+373`,
    country: `MD`,
  },
  {
    countryName: `Monaco`,
    code: `+377`,
    country: `MC`,
  },
  {
    countryName: `Mongolia`,
    code: `+976`,
    country: `MN`,
  },
  {
    countryName: `Montenegro`,
    code: `+382`,
    country: `ME`,
  },
  {
    countryName: `Montserrat`,
    code: `+1664`,
    country: `MS`,
  },
  {
    countryName: `Morocco`,
    code: `+212`,
    country: `MA`,
  },
  {
    countryName: `Mozambique`,
    code: `+258`,
    country: `MZ`,
  },
  {
    countryName: `Myanmar`,
    code: `+95`,
    country: `MM`,
  },
  {
    countryName: `Namibia`,
    code: `+264`,
    country: `NA`,
  },
  {
    countryName: `Nauru`,
    code: `+674`,
    country: `NR`,
  },
  {
    countryName: `Nepal`,
    code: `+977`,
    country: `NP`,
  },
  {
    countryName: `Netherlands`,
    code: `+31`,
    country: `NL`,
  },
  {
    countryName: `New Caledonia`,
    code: `+687`,
    country: `NC`,
  },
  {
    countryName: `New Zealand`,
    code: `+64`,
    country: `NZ`,
  },
  {
    countryName: `Nicaragua`,
    code: `+505`,
    country: `NI`,
  },
  {
    countryName: `Niger`,
    code: `+227`,
    country: `NE`,
  },
  {
    countryName: `Nigeria`,
    code: `+234`,
    country: `NG`,
  },
  {
    countryName: `Niue`,
    code: `+683`,
    country: `NU`,
  },
  {
    countryName: `Northern Mariana Islands`,
    code: `+1670`,
    country: `MP`,
  },
  {
    countryName: `Norway`,
    code: `+47`,
    country: `NO`,
  },
  {
    countryName: `Oman`,
    code: `+968`,
    country: `OM`,
  },
  {
    countryName: `Pakistan`,
    code: `+92`,
    country: `PK`,
  },
  {
    countryName: `Palau`,
    code: `+680`,
    country: `PW`,
  },
  {
    countryName: `Palestinian Territory, Occupied`,
    code: `+970`,
    country: `PS`,
  },
  {
    countryName: `Panama`,
    code: `+507`,
    country: `PA`,
  },
  {
    countryName: `Papua New Guinea`,
    code: `+675`,
    country: `PG`,
  },
  {
    countryName: `Paraguay`,
    code: `+595`,
    country: `PY`,
  },
  {
    countryName: `Peru`,
    code: `+51`,
    country: `PE`,
  },
  {
    countryName: `Philippines`,
    code: `+63`,
    country: `PH`,
  },
  {
    countryName: `Pitcairn`,
    code: `+872`,
    country: `PN`,
  },
  {
    countryName: `Poland`,
    code: `+48`,
    country: `PL`,
  },
  {
    countryName: `Portugal`,
    code: `+351`,
    country: `PT`,
  },
  {
    countryName: `Puerto Rico`,
    code: `+1939`,
    country: `PR`,
  },
  {
    countryName: `Qatar`,
    code: `+974`,
    country: `QA`,
  },
  {
    countryName: `Réunion`,
    code: `+262`,
    country: `RE`,
  },
  {
    countryName: `Romania`,
    code: `+40`,
    country: `RO`,
  },
  {
    countryName: `Russia`,
    code: `+7`,
    country: `RU`,
  },
  {
    countryName: `Rwanda`,
    code: `+250`,
    country: `RW`,
  },
  {
    countryName: `Saint Barthélemy`,
    code: `+590`,
    country: `BL`,
  },
  {
    countryName: `Saint Helena, Ascension and Tristan Da Cunha`,
    code: `+290`,
    country: `SH`,
  },
  {
    countryName: `Saint Kitts and Nevis`,
    code: `+1869`,
    country: `KN`,
  },
  {
    countryName: `Saint Lucia`,
    code: `+1758`,
    country: `LC`,
  },
  {
    countryName: `Saint Martin`,
    code: `+590`,
    country: `MF`,
  },
  {
    countryName: `Saint Pierre and Miquelon`,
    code: `+508`,
    country: `PM`,
  },
  {
    countryName: `Saint Vincent and the Grenadines`,
    code: `+1784`,
    country: `VC`,
  },
  {
    countryName: `Samoa`,
    code: `+685`,
    country: `WS`,
  },
  {
    countryName: `San Marino`,
    code: `+378`,
    country: `SM`,
  },
  {
    countryName: `Sao Tome and Principe`,
    code: `+239`,
    country: `ST`,
  },
  {
    countryName: `Saudi Arabia`,
    code: `+966`,
    country: `SA`,
  },
  {
    countryName: `Senegal`,
    code: `+221`,
    country: `SN`,
  },
  {
    countryName: `Serbia`,
    code: `+381`,
    country: `RS`,
  },
  {
    countryName: `Seychelles`,
    code: `+248`,
    country: `SC`,
  },
  {
    countryName: `Sierra Leone`,
    code: `+232`,
    country: `SL`,
  },
  {
    countryName: `Singapore`,
    code: `+65`,
    country: `SG`,
  },
  {
    countryName: `Slovakia`,
    code: `+421`,
    country: `SK`,
  },
  {
    countryName: `Slovenia`,
    code: `+386`,
    country: `SI`,
  },
  {
    countryName: `Solomon Islands`,
    code: `+677`,
    country: `SB`,
  },
  {
    countryName: `Somalia`,
    code: `+252`,
    country: `SO`,
  },
  {
    countryName: `South Africa`,
    code: `+27`,
    country: `ZA`,
  },
  {
    countryName: `South Georgia and the South Sandwich Islands`,
    code: `+500`,
    country: `GS`,
  },
  {
    countryName: `Spain`,
    code: `+34`,
    country: `ES`,
  },
  {
    countryName: `Sri Lanka`,
    code: `+94`,
    country: `LK`,
  },
  {
    countryName: `Sudan`,
    code: `+249`,
    country: `SD`,
  },
  {
    countryName: `Suriname`,
    code: `+597`,
    country: `SR`,
  },
  {
    countryName: `Svalbard and Jan Mayen`,
    code: `+47`,
    country: `SJ`,
  },
  {
    countryName: `Swaziland`,
    code: `+268`,
    country: `SZ`,
  },
  {
    countryName: `Sweden`,
    code: `+46`,
    country: `SE`,
  },
  {
    countryName: `Switzerland`,
    code: `+41`,
    country: `CH`,
  },
  {
    countryName: `Syrian Arab Republic`,
    code: `+963`,
    country: `SY`,
  },
  {
    countryName: `Taiwan, Province of China`,
    code: `+886`,
    country: `TW`,
  },
  {
    countryName: `Tajikistan`,
    code: `+992`,
    country: `TJ`,
  },
  {
    countryName: `Tanzania, United Republic of`,
    code: `+255`,
    country: `TZ`,
  },
  {
    countryName: `Thailand`,
    code: `+66`,
    country: `TH`,
  },
  {
    countryName: `Timor-Leste`,
    code: `+670`,
    country: `TL`,
  },
  {
    countryName: `Togo`,
    code: `+228`,
    country: `TG`,
  },
  {
    countryName: `Tokelau`,
    code: `+690`,
    country: `TK`,
  },
  {
    countryName: `Tonga`,
    code: `+676`,
    country: `TO`,
  },
  {
    countryName: `Trinidad and Tobago`,
    code: `+1868`,
    country: `TT`,
  },
  {
    countryName: `Tunisia`,
    code: `+216`,
    country: `TN`,
  },
  {
    countryName: `Turkey`,
    code: `+90`,
    country: `TR`,
  },
  {
    countryName: `Turkmenistan`,
    code: `+993`,
    country: `TM`,
  },
  {
    countryName: `Turks and Caicos Islands`,
    code: `+1649`,
    country: `TC`,
  },
  {
    countryName: `Tuvalu`,
    code: `+688`,
    country: `TV`,
  },
  {
    countryName: `Uganda`,
    code: `+256`,
    country: `UG`,
  },
  {
    countryName: `Ukraine`,
    code: `+380`,
    country: `UA`,
  },
  {
    countryName: `United Arab Emirates`,
    code: `+971`,
    country: `AE`,
  },
  {
    countryName: `United States`,
    code: `+1`,
    country: `US`,
  },
  {
    countryName: `Uruguay`,
    code: `+598`,
    country: `UY`,
  },
  {
    countryName: `Uzbekistan`,
    code: `+998`,
    country: `UZ`,
  },
  {
    countryName: `Vanuatu`,
    code: `+678`,
    country: `VU`,
  },
  {
    countryName: `Venezuela, Bolivarian Republic of`,
    code: `+58`,
    country: `VE`,
  },
  {
    countryName: `Viet Nam`,
    code: `+84`,
    country: `VN`,
  },
  {
    countryName: `Virgin Islands, British`,
    code: `+1284`,
    country: `VG`,
  },
  {
    countryName: `Virgin Islands, U.S.`,
    code: `+1340`,
    country: `VI`,
  },
  {
    countryName: `Wallis and Futuna`,
    code: `+681`,
    country: `WF`,
  },
  {
    countryName: `Yemen`,
    code: `+967`,
    country: `YE`,
  },
  {
    countryName: `Zambia`,
    code: `+260`,
    country: `ZM`,
  },
  {
    countryName: `Zimbabwe`,
    code: `+263`,
    country: `ZW`,
  },
];
