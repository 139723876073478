import styled from 'styled-components';
import React from 'react';
import { ITextInputProps } from './TextInput.interface';

const Wrapper = styled.div``;

const Input = styled.input`
  width: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
  font-family: 'Montserrat';
  font-style: normal;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;

  padding: 0;
  &:disabled {
    background: #f4f7f9;
  }
  ::placeholder {
    outline: none;
    color: #000;
    font-size: 17px;
    font-family: 'Montserrat';
    font-weight: 300;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
  }
`;

interface WrapperTextFieldProps {
  // variant?: TextInputErrorVariant;
  disabled?: boolean;
}

const WrapperTextField = styled.div<WrapperTextFieldProps>`
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(129, 129, 165, 0.2);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  box-sizing: border-box;
`;

// const IconWrapper = styled.span`
//   display: flex;
//   align-items: center;
// `;

const InputError = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ef6355;
  padding-top: 5px;
`;

const TextInput: React.FunctionComponent<ITextInputProps> = ({
  // leftIcon,
  disabled,
  error,
  disableErrorMessage,
  className,
  ...rest
}) => (
  <Wrapper className={className}>
    <WrapperTextField disabled={disabled}>
      {/* {leftIcon != null && <IconWrapper>{leftIcon()}</IconWrapper>} */}
      <Input disabled={disabled} {...rest} />
    </WrapperTextField>
    {error && !disableErrorMessage && <InputError>{error}</InputError>}
  </Wrapper>
);

export default TextInput;
